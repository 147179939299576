import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: 'Home' }
  },
  {
    path: "/bio",
    name: "Bio",
    beforeEnter: () => {
    window.location.href = 'https://www.bio.kydo.lol';
        },
    meta: { title: 'Bio' }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
    meta: { title: 'About' }
  },
  {
    path: '/project',
    name: 'project',
    component: () => import('../views/ProjectView.vue'),
    meta: { title: 'Project' }
  },
  {
    path: '/read/:slug/:id',
    name: 'articleDetail',
    component: () => import('../views/ArticleView.vue'),
    meta: { title: 'Blog' }
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import('../views/BlogView.vue'),
    meta: { title: 'Blog' }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/ContactView.vue'),
    meta: { title: 'Contact' }
  },
  {
    path: "/discord",
    name: "Discord",
    beforeEnter: () => {
    window.location.href = 'https://discord.gg/CDD223E3W3';
        },
    meta: { title: 'Discord' }
  },
  {
    path: "/support",
    name: "Support",
    beforeEnter: () => {
    window.location.href = 'https://discord.gg/CDD223E3W3';
        },
    meta: { title: 'Support' }
  },
  {
    path: "/invite",
    name: "Invite",
    beforeEnter: () => {
    window.location.href = 'https://discord.com/oauth2/authorize?client_id=913029521815502869&permissions=8&scope=bot+applications.commands';
        },
    meta: { title: 'Invite' }
  },
  {
    path: "/invite2",
    name: "Invite",
    beforeEnter: () => {
    window.location.href = 'https://discord.com/oauth2/authorize?client_id=985892032738394132&permissions=8&scope=bot+applications.commands';
        },
    meta: { title: 'Invite' }
  },
  {
    path: "/invite3",
    name: "Invite",
    beforeEnter: () => {
    window.location.href = 'https://discord.com/oauth2/authorize?client_id=985892084382830643&permissions=8&scope=bot+applications.commands';
        },
    meta: { title: 'Invite' }
  },
  {
    path: "/soulvibe",
    name: "Website",
    beforeEnter: () => {
    window.location.href = 'https://soulvibe.xyz  ';
        },
    meta: { title: 'Website' }
  },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.title + ' - kydo();'; // Mengambil judul dari meta atau mengatur judul default jika tidak ada
  next();
});

export default router
